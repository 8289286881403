exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-donation-policy-jsx": () => import("./../../../src/pages/donation-policy.jsx" /* webpackChunkName: "component---src-pages-donation-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank_you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-all-candidates-template-jsx": () => import("./../../../src/templates/allCandidatesTemplate.jsx" /* webpackChunkName: "component---src-templates-all-candidates-template-jsx" */),
  "component---src-templates-faq-template-jsx": () => import("./../../../src/templates/faqTemplate.jsx" /* webpackChunkName: "component---src-templates-faq-template-jsx" */)
}

